import React from 'react';

import { Box, Container, Typography } from '@mui/material';

import LogoDark from 'assets/images/logos/logo-long-dark.png';

import useStyles from './styles';

export default function Footer(props) {
    const classes = useStyles();

    return (
        <Container maxWidth={'md'} className={classes.root} sx={props.sx}>
            {/* <img src={LogoDark} className={classes.logo} /> */}
            <Typography variant="font3" sx={{ marginTop: 1 }}>
                Relief Consultants, LLC. Copyright © 2022 All Right Reserved
            </Typography>
            <a href={'https://thereliefconsultants.com/privacy'}>
                <Typography variant="font3">Privacy Policy</Typography>
            </a>
        </Container>
    );
}
