import * as React from 'react';
// Modules
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'redux/actions/user';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
// UI
import { Box, AppBar, IconButton, Toolbar, Hidden, Container, Button, Typography } from '@mui/material';
import { Menu, ExitToApp, AccountCircle } from '@mui/icons-material';
import MobileDrawer from './Drawer/Drawer';
// images
import Logo from 'assets/images/logos/logo-long-dark.png';
// Styles
import useStyles from './styles';

export default function Navigation({ blank }) {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const { user } = useSelector(({ user }) => ({ user }));
    const dispatch = useDispatch();
    const location = useLocation();

    async function handleLogout() {
        setDrawerOpen(false);
        await dispatch(signOut());
    }

    if (blank) {
        return (
            <AppBar position="sticky" sx={{ boxShadow: 1 }}>
                <Toolbar classes={{ root: classes.toolbar }}>
                    <Container maxWidth="lg" className={classes.navContainerBlank}>
                        <img src={Logo} className={classes.logo} />
                    </Container>
                </Toolbar>
            </AppBar>
        );
    }

    return (
        <AppBar position="sticky" sx={{ boxShadow: 1 }}>
            <Toolbar classes={{ root: classes.toolbar }}>
                <Container maxWidth="lg" className={classes.navContainer}>
                    <Link to="/">
                        <img src={Logo} className={classes.logo} />
                    </Link>
                    <Hidden mdUp>
                        <IconButton onClick={() => setDrawerOpen(true)}>
                            <Menu />
                        </IconButton>
                    </Hidden>
                    <Hidden mdDown>
                        <Box sx={{ flexGrow: 1 }}></Box>
                        {user ? (
                            <Box className={classes.rightContent}>
                                <Link to="/">
                                    <Typography
                                        sx={{ marginRight: 2 }}
                                        className={clsx(classes.link, {
                                            [classes.activeLink]: location.pathname === '/',
                                        })}
                                        noWrap
                                    >
                                        My Application
                                    </Typography>
                                </Link>
                                <IconButton sx={{ marginLeft: 1 }} component={Link} to={`/profile/${user.id}`}>
                                    <AccountCircle />
                                </IconButton>
                                <IconButton sx={{ marginLeft: 1 }} onClick={handleLogout}>
                                    <ExitToApp />
                                </IconButton>
                            </Box>
                        ) : (
                            <Box className={classes.rightContent}>
                                <Button variant="contained" size="large" component={Link} to="/login">
                                    Log in
                                </Button>
                                <Button variant="outlined" size="large" sx={{ marginLeft: 2 }} component={Link} to="/start">
                                    Start Application
                                </Button>
                            </Box>
                        )}
                    </Hidden>
                </Container>
            </Toolbar>
            <MobileDrawer open={drawerOpen} onLogout={handleLogout} onClose={() => setDrawerOpen(false)} />
        </AppBar>
    );
}
