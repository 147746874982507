import * as React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'classnames';
// MUI
import {
    IconButton,
    Box,
    Button,
    Drawer,
    List,
    ListItem,
    Typography,
} from '@mui/material';
// images
import Logo from 'assets/images/logos/logo-long-dark.png';
import { Close } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
// styles
import useStyles from './styles';

export default function MiniDrawer({ open, onClose, onLogout }) {
    const { user } = useSelector(({ user }) => ({ user }));
    const classes = useStyles();
    const location = useLocation();

    function handleLogout() {
        onLogout();
    }

    React.useEffect(() => {
        onClose();
    }, [location.pathname]);

    return (
        <Drawer
            anchor={'right'}
            open={open}
            classes={{ paper: classes.drawer }}
        >
            <Box role="presentation" className={classes.drawerBox}>
                <Box className={classes.drawerHeader}>
                    <img src={Logo} className={classes.logo} />
                    <IconButton onClick={onClose}>
                        <Close size={16} />
                    </IconButton>
                </Box>
                <List>
                    {user ? (
                        <Box className={classes.links}>
                            {user.lastAppId && (
                                <Link to={`/application/${user.lastAppId}`}>
                                    <Typography
                                        sx={{ marginBottom: 1 }}
                                        className={clsx(classes.link, {
                                            [classes.activeLink]:
                                                location.pathname.includes(
                                                    'application',
                                                ),
                                        })}
                                    >
                                        Application
                                    </Typography>
                                </Link>
                            )}
                            {user.lastAppId && (
                                <Link to={`/status/${user.lastAppId}`}>
                                    <Typography
                                        sx={{ marginBottom: 1 }}
                                        className={clsx(classes.link, {
                                            [classes.activeLink]:
                                                location.pathname.includes(
                                                    'status',
                                                ),
                                        })}
                                    >
                                        Status
                                    </Typography>
                                </Link>
                            )}
                            {user.lastAppId && (
                                <Box className={classes.divider} />
                            )}
                            <Link to="/">
                                <Typography
                                    sx={{ marginBottom: 1 }}
                                    className={clsx(classes.link, {
                                        [classes.activeLink]:
                                            location.pathname === '/',
                                    })}
                                >
                                    My Applications
                                </Typography>
                            </Link>
                            <Link to="/profile">
                                <Typography
                                    sx={{ marginBottom: 1 }}
                                    className={clsx(classes.link, {
                                        [classes.activeLink]:
                                            location.pathname === '/profile',
                                    })}
                                >
                                    Profile
                                </Typography>
                            </Link>
                            <Typography
                                sx={{ marginBottom: 1 }}
                                onClick={handleLogout}
                                className={clsx(classes.link)}
                            >
                                Logout
                            </Typography>
                        </Box>
                    ) : (
                        <>
                            <ListItem sx={{ paddingLeft: 0 }}>
                                <Button
                                    variant="contained"
                                    sx={{ marginRight: { xs: 0.5, sm: 2 } }}
                                >
                                    <Link to="/">Login</Link>
                                </Button>
                                <Button variant="outlined" color="primary">
                                    <Link to="/signup">
                                        Start New Application
                                    </Link>
                                </Button>
                            </ListItem>
                        </>
                    )}
                </List>
            </Box>
        </Drawer>
    );
}
