import React, { useState } from 'react';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import { Box, Paper, Typography } from '@mui/material';
import DisqualifiedImage from 'assets/images/stock/documents.png';
import useStyles from './styles';
import { LoadingButton } from '@mui/lab';

export default function Disqualified({ form, onError, onContinue }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);

    async function handleRestart() {
        setSubmitting(true);
        try {
            const response = await axios.patch(
                `${ApiResource.endpoint}/self-employment/restart`,
                {},
                {
                    headers: {
                        'x-auth': form.authToken,
                    },
                },
            );

            onContinue(response.data.form);
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    return (
        <form onSubmit={handleRestart}>
            <Box className={classes.root}>
                <Typography variant="h5" fontWeight={'400'} sx={{ marginBottom: 1 }}>
                    Not qualified
                </Typography>
                <Typography variant="font2" fontWeight={'400'} sx={{ marginBottom: 4 }}>
                    Add some description text about why we are asking for this information. Add some description text about why we are
                    asking for this information. Add some description text about why we are asking for this information. Add some
                    description text about why we are asking for this information.
                </Typography>
                <input type="submit" style={{ display: 'none' }} />
                <Box sx={{ marginTop: 3 }} className={classes.form}>
                    <img src={DisqualifiedImage} className={classes.disqualImage} />
                </Box>

                <Box className={classes.controls} sx={{ marginTop: 6 }}>
                    <div />
                    <LoadingButton loading={submitting} onClick={handleRestart} variant="contained">
                        Restart
                    </LoadingButton>
                </Box>
            </Box>
        </form>
    );
}
