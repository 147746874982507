import React from 'react';
// UI
import { Button, Box, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
// Imges
import WorkStock from 'assets/images/stock/work-stock.png';
// Styles
import useStyles from './styles';

export default function Home() {
    const classes = useStyles();

    return (
        <Container maxWidth="xs" className={classes.root}>
            <Typography sx={{ marginTop: 4, marginBottom: 4, fontWeight: '700' }} variant="h3">
                Apply for Self Employment Covid Relief Credit
            </Typography>
            <img src={WorkStock} className={classes.image} />
            <Button
                className={classes.button}
                variant="contained"
                size="large"
                fullWidth
                component={Link}
                to="/start"
                sx={{ marginTop: 3 }}
            >
                Signup & Start a new Application
            </Button>
            <Button
                className={classes.button}
                variant="contained"
                size="large"
                fullWidth
                component={Link}
                to="/login"
                sx={{ marginTop: 3 }}
            >
                Pick up where I left off
            </Button>
            <Box sx={{ width: '100%' }}>
                <Typography sx={{ marginTop: 3, marginBottom: 1, textAlign: 'left' }}>Already filed?</Typography>
                <Button className={classes.button} variant="outlined" size="large" component={Link} to="/login" fullWidth>
                    View Application Status
                </Button>
            </Box>
        </Container>
    );
}
