import React, { useState } from 'react';
import { Box, Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { ChevronLeft } from '@mui/icons-material';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import useStyles from './styles';

export default function Income({ form, onBack, onError, onContinue }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: {
            income2019: form.fields?.income?.income2019,
            income2020: form.fields?.income?.income2020,
            income2021: form.fields?.income?.income2021,
        },
    });

    async function handleContinue(data) {
        setSubmitting(true);
        try {
            const response = await axios.patch(
                `${ApiResource.endpoint}/self-employment`,
                {
                    step: 1,
                    income: {
                        ...data,
                    },
                },
                {
                    headers: {
                        'x-auth': form.authToken,
                    },
                },
            );

            onContinue(response.data.form);
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    return (
        <form onSubmit={handleSubmit(handleContinue)}>
            <Box className={classes.root}>
                <Typography variant="h5" fontWeight={'400'} sx={{ marginBottom: 1 }}>
                    Net Income
                </Typography>
                <Typography variant="font2" fontWeight={'400'} sx={{ marginBottom: 4 }}>
                    Add some description text about why we are asking for this information. Add some description text about why we are
                    asking for this information. Add some description text about why we are asking for this information. Add some
                    description text about why we are asking for this information.
                </Typography>
                <input type="submit" style={{ display: 'none' }} />
                <Box sx={{ marginTop: 4 }}>
                    <Typography variant="h5" sx={{ fontWeight: '700', marginBottom: 3 }}>
                        In 2019 what were your net earnings from Self-Employment?
                    </Typography>
                    <FormControl>
                        <Controller
                            name="income2019"
                            control={control}
                            rules={{ required: 'Field Required' }}
                            render={({ field }) => (
                                <RadioGroup {...field} onChange={(event, value) => field.onChange(value)} value={field.value}>
                                    <FormControlLabel value="1" control={<Radio />} label="Less than $30,000" />
                                    <FormControlLabel value="2" control={<Radio />} label="Between $30,000 and $132,860" />
                                    <FormControlLabel value="3" control={<Radio />} label="More than $132,860" />
                                </RadioGroup>
                            )}
                        />
                    </FormControl>
                    {errors?.income2019 && (
                        <Typography variant="font5" color="error">
                            {errors?.income2019.message}
                        </Typography>
                    )}
                </Box>
                <Box sx={{ marginTop: 4 }}>
                    <Typography variant="h5" sx={{ fontWeight: '700', marginBottom: 3 }}>
                        In 2020 what were your net earnings from Self-Employment?
                    </Typography>
                    <FormControl>
                        <Controller
                            name="income2020"
                            control={control}
                            rules={{ required: 'Field Required' }}
                            render={({ field }) => (
                                <RadioGroup {...field} onChange={(event, value) => field.onChange(value)} value={field.value}>
                                    <FormControlLabel value="1" control={<Radio />} label="Less than $30,000" />
                                    <FormControlLabel value="2" control={<Radio />} label="Between $30,000 and $132,860" />
                                    <FormControlLabel value="3" control={<Radio />} label="More than $132,860" />
                                </RadioGroup>
                            )}
                        />
                    </FormControl>
                    {errors?.income2020 && (
                        <Typography variant="font5" color="error">
                            {errors?.income2020.message}
                        </Typography>
                    )}
                </Box>
                <Box sx={{ marginTop: 4 }}>
                    <Typography variant="h5" sx={{ fontWeight: '700', marginBottom: 3 }}>
                        In 2021 what were your net earnings from Self-Employment?
                    </Typography>
                    <FormControl>
                        <Controller
                            name="income2021"
                            control={control}
                            rules={{ required: 'Field Required' }}
                            render={({ field }) => (
                                <RadioGroup {...field} onChange={(event, value) => field.onChange(value)} value={field.value}>
                                    <FormControlLabel value="1" control={<Radio />} label="Less than $30,000" />
                                    <FormControlLabel value="2" control={<Radio />} label="Between $30,000 and $132,860" />
                                    <FormControlLabel value="3" control={<Radio />} label="More than $132,860" />
                                </RadioGroup>
                            )}
                        />
                    </FormControl>
                    {errors?.income2021 && (
                        <Typography variant="font5" color="error">
                            {errors?.income2021.message}
                        </Typography>
                    )}
                </Box>

                <Box className={classes.controls} sx={{ marginTop: 6 }}>
                    <Button onClick={onBack} startIcon={<ChevronLeft />}>
                        Back
                    </Button>
                    <LoadingButton loading={submitting} onClick={handleSubmit(handleContinue)} variant="contained">
                        Continue
                    </LoadingButton>
                </Box>
            </Box>
        </form>
    );
}
