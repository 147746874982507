import React, { useState } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import useStyles from './styles';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import { ChevronLeft } from '@mui/icons-material';

export default function Documents({ form, onBack, onError, onContinue }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: form.email,
            name: form.name,
            phone: form.phone,
            address: form.address,
        },
    });

    async function handleContinue(data) {
        setSubmitting(true);
        try {
            const response = await axios.patch(
                `${ApiResource.endpoint}/self-employment`,
                {
                    step: 5,
                    form7202_2020: {
                        ...data,
                    },
                },
                {
                    headers: {
                        'x-auth': form.authToken,
                    },
                },
            );

            onContinue(response.data.form);
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    return (
        <form onSubmit={handleSubmit(handleContinue)}>
            <Box className={classes.root}>
                <Typography variant="h5" fontWeight={'400'} sx={{ marginBottom: 1 }}>
                    Document Submission
                </Typography>
                <Typography variant="font2" fontWeight={'400'} sx={{ marginBottom: 4 }}>
                    Add some description text about why we are asking for this information. Add some description text about why we are
                    asking for this information. Add some description text about why we are asking for this information. Add some
                    description text about why we are asking for this information.
                </Typography>
                <input type="submit" style={{ display: 'none' }} />
                <Box sx={{ marginTop: 3 }} className={classes.form}></Box>

                <Box className={classes.controls} sx={{ marginTop: 6 }}>
                    <Button onClick={onBack} startIcon={<ChevronLeft />}>
                        Back
                    </Button>
                    <LoadingButton loading={submitting} onClick={handleSubmit(handleContinue)} variant="contained">
                        Continue
                    </LoadingButton>
                </Box>
            </Box>
        </form>
    );
}
