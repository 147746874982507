import React, { useEffect } from 'react';

// Modules
import { useDispatch } from 'react-redux';
// Components
import { Navigate, Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import Navigation from 'components/Navigation/Navigation';
import Footer from 'components/Widgets/Footer/Footer';

import Form from './Form/Form';
import useStyles from './styles';

const AuthStack = (props) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Navigation />
            <Box sx={{ flexGrow: 1 }}>
                <Routes>
                    <Route path="" element={<Form />} />
                    {/* End Routes */}

                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Box>
            <Footer sx={{ marginTop: 6 }} />
        </Box>
    );
};

export default AuthStack;
