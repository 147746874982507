import React, { useEffect } from 'react';
// Modules
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import queryString from 'query-string';
import { getCurrentUser, setUser } from 'redux/actions/user';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';

// Styles
import FullscreenLoader from 'components/Widgets/FullscreenLoader';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';

export default function Authenticate({ done }) {
    const dispatch = useDispatch();
    const { user } = useSelector(({ user }) => ({ user }));
    const { search } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        handleAuthenticate();
    }, []);

    async function handleAuthenticate() {
        const { email, token, redirect } = queryString.parse(search);
        try {
            const response = await axios.post(`${ApiResource.endpoint}/self-employment/authenticate`, {
                email,
                password: token,
            });
            dispatch(setUser({ authToken: response.data.token, ...response.data.form }));

            if (redirect) {
                navigate(redirect);
            }
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
            navigate('/');
        }
        // try {
        //     const congitoUser = await Auth.signIn(email);
        //     await Auth.sendCustomChallengeAnswer(congitoUser, code);

        //     await dispatch(getCurrentUser());
        //     if (redirect) {
        //         navigate(redirect);
        //     }
        // } catch (error) {
        //     dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        //     navigate('/');
        // }
    }

    return <FullscreenLoader text="Authenticating..." />;
}
