import React, { useState } from 'react';
// Modules
import { setUser } from 'redux/actions/user';
import ApiResource from 'integrations/api/api_resource';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

// Components
import { Container, Typography, TextField, Paper, Box } from '@mui/material';
import { Fade } from 'react-reveal';
import { LoadingButton } from '@mui/lab';
// styles
import useStyles from './styles';

export default function ResetPassword() {
    const classes = useStyles();
    const [state, setState] = useState();
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();

    const location = useLocation();
    const query = queryString.parse(location.search);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    async function processSubmit(data) {
        setSubmitting(true);
        try {
            const response = await axios.patch(`${ApiResource.endpoint}/self-employment/reset-password`, {
                ...data,
                email: query.email,
                passwordResetToken: query.token,
            });

            dispatch(setUser({ authToken: response.data.token, ...response.data.form }));
        } catch (error) {
            dispatch(
                setSnackbar({
                    severity: 'error',
                    message: checkError(error),
                }),
            );
        }
        setSubmitting(false);
    }

    return (
        <form onSubmit={handleSubmit(processSubmit)}>
            <Fade up>
                <Container maxWidth="xs" className={classes.root}>
                    <Paper sx={{ paddingY: 3, paddingX: 4, width: '100%' }}>
                        <Typography
                            sx={{
                                marginTop: 2,
                                fontWeight: '500',
                            }}
                            variant="h3"
                        >
                            Reset Password
                        </Typography>
                        <Typography variant="font3" sx={{ fontWeight: '400', marginTop: 2, marginBottom: 2 }}>
                            Input your a new password
                        </Typography>
                        <input type="submit" style={{ display: 'none' }} />
                        <TextField
                            label="Email Address"
                            placeholder="e.g. example@example.com"
                            variant="outlined"
                            fullWidth
                            defaultValue={query.email}
                            className={classes.field}
                            sx={{ marginBottom: 2 }}
                            disabled={true}
                        />
                        <TextField
                            error={!!errors.password}
                            label="New Password"
                            placeholder="********"
                            type="password"
                            variant="outlined"
                            fullWidth
                            className={classes.field}
                            {...register('password')}
                        />
                        <Box sx={{ marginTop: 2 }}>
                            <LoadingButton
                                variant="contained"
                                loading={submitting}
                                className={classes.button}
                                onClick={handleSubmit(processSubmit)}
                            >
                                Submit
                            </LoadingButton>
                        </Box>
                    </Paper>
                </Container>
            </Fade>
        </form>
    );
}
