import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import { SET_CURRENT_USER, LOGOUT, USER_ERROR } from '../reducers/user';

export const getCurrentUser = () => async (dispatch, getState) => {
    const state = getState();
    if (!state.user) return;

    try {
        const response = await axios.get(`${ApiResource.endpoint}/self-employment`, {
            headers: {
                'x-auth': state.user.authToken,
            },
        });

        dispatch({
            payload: { ...state.user, ...response.data.form },
            type: SET_CURRENT_USER,
        });

        return response.data.form;
    } catch (error) {
        dispatch(signOut());
        throw error;
    }
};

export const setUser = (payload) => async (dispatch, getState) => {
    const state = getState();

    dispatch({
        payload: { ...state.user, ...payload },
        type: SET_CURRENT_USER,
    });
};

export const signOut = () => async (dispatch) => {
    try {
        dispatch({
            payload: null,
            type: LOGOUT,
        });

        return true;
    } catch (error) {
        console.log(error);
        dispatch({
            type: USER_ERROR,
        });

        return false;
    }
};
