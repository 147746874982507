let endpoint;
// load the correct aws config options

switch (process.env.REACT_APP_VERCEL_ENV) {
    case 'production':
        endpoint = 'https://api.thereliefconsultants.com/api/v1';
        break;
    case 'development':
    case 'preview':
        endpoint = 'https://dev.api.thereliefconsultants.com/api/v1';
        break;
    default:
        endpoint = 'http://localhost:3000/api/v1';
        break;
}

const ApiResource = { endpoint };

export default ApiResource;
