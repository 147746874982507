import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import { useDispatch } from 'react-redux';
import { getCurrentUser } from 'redux/actions/user';

import Snackbar from './components/Widgets/Snackbar';
import Authorizer from './Authorizer';

const Router = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCurrentUser());
    }, []);

    return (
        <React.Fragment>
            <Snackbar />
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<Authorizer />} />
                </Routes>
            </BrowserRouter>
        </React.Fragment>
    );
};

export default Router;
