import React, { useState } from 'react';
import { Box, Paper, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import validator from 'validator';
import phone from 'phone';
import useStyles from './styles';

export default function BasicInformation({ form, onError, onContinue }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: form.email,
            name: form.name,
            phone: form.phone,
            address: form.address,
        },
    });

    async function handleContinue(data) {
        setSubmitting(true);
        try {
            const response = await axios.patch(
                `${ApiResource.endpoint}/self-employment`,
                {
                    step: 0,
                    ...data,
                },
                {
                    headers: {
                        'x-auth': form.authToken,
                    },
                },
            );

            onContinue(response.data.form);
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    return (
        <form onSubmit={handleSubmit(handleContinue)}>
            <Box className={classes.root}>
                <Typography variant="h5" fontWeight={'400'} sx={{ marginBottom: 1 }}>
                    Basic Information
                </Typography>
                <Typography variant="font2" fontWeight={'400'} sx={{ marginBottom: 4 }}>
                    Add some description text about why we are asking for this information. Add some description text about why we are
                    asking for this information. Add some description text about why we are asking for this information. Add some
                    description text about why we are asking for this information.
                </Typography>
                <input type="submit" style={{ display: 'none' }} />
                <Box sx={{ marginTop: 3 }} className={classes.form}>
                    <TextField
                        error={!!errors.email}
                        label="Email Address"
                        placeholder="e.g. example@example.com"
                        variant="outlined"
                        fullWidth
                        className={classes.field}
                        helperText={errors?.email?.message}
                        {...register('email', {
                            required: 'Invalid email address',
                            validate: (value) => validator.isEmail(value),
                        })}
                    />
                    <TextField
                        error={!!errors.name}
                        label="Full name"
                        placeholder="e.g. Don Butterscotch"
                        variant="outlined"
                        fullWidth
                        className={classes.field}
                        helperText={errors?.name?.message}
                        {...register('name', {
                            required: 'Required Field',
                        })}
                    />
                    <TextField
                        error={!!errors.phone}
                        label="Phone Number"
                        placeholder="e.g. 6025555555"
                        variant="outlined"
                        fullWidth
                        className={classes.field}
                        helperText={errors?.phone?.message}
                        {...register('phone', {
                            required: 'Invalid phone number',
                            validate: (value) => phone(value).isValid,
                        })}
                    />
                </Box>

                <Box className={classes.controls} sx={{ marginTop: 6 }}>
                    <div />
                    <LoadingButton loading={submitting} onClick={handleSubmit(handleContinue)} variant="contained">
                        Continue
                    </LoadingButton>
                </Box>
            </Box>
        </form>
    );
}
