import { createTheme } from '@mui/material/styles';

export const AppColors = {
    Success: '#4AAF41',
    primary: '#3988B1',
    Error: '#f50057',
    OffWhite: 'rgba(255,255,255,0.5)',
};

// const palette = {};
// Object.keys(AppColors).forEach((key) => {
//     palette[key] = { main: AppColors[key], contrastText: '#fff' };
// });

const { breakpoints } = createTheme({});

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: '#116EEA',
        },
        secondary: {
            main: '#f7f8fa',
        },
    },
    typography: {
        fontFamily: `'Open Sans', Roboto`,
        /** Normal */
        h1: {
            fontSize: 48,
            [breakpoints.down('sm')]: {
                fontSize: 29,
            },
        },
        h2: {
            fontSize: 36,
            [breakpoints.down('sm')]: {
                fontSize: 24,
            },
        },
        h3: {
            fontSize: 32,
            [breakpoints.down('sm')]: {
                fontSize: 24,
            },
        },
        h4: {
            fontSize: 26,
            [breakpoints.down('sm')]: {
                fontSize: 22,
            },
        },
        h5: {
            fontSize: 22,
            [breakpoints.down('sm')]: {
                fontSize: 18,
            },
        },
        h6: {
            fontSize: 20,
            [breakpoints.down('sm')]: {
                fontSize: 17,
            },
        },
        font1: {
            fontSize: 18,
            fontFamily: 'Montserrat',

            display: 'block',
            [breakpoints.down('sm')]: {
                fontSize: 16,
            },
        },

        font2: {
            fontSize: 16,
            fontFamily: 'Montserrat',

            display: 'block',
            [breakpoints.down('sm')]: {
                fontSize: 15,
            },
        },
        font3: {
            fontSize: 14,
            fontFamily: 'Montserrat',
            fontWeight: 200,

            display: 'block',
            [breakpoints.down('sm')]: {
                fontSize: 13,
            },
        },
        font4: {
            fontSize: 12,
            fontFamily: 'Montserrat',

            display: 'block',
            [breakpoints.down('sm')]: {
                fontSize: 11,
            },
        },
        font5: {
            fontSize: 11,
            fontFamily: 'Montserrat',

            display: 'block',
            [breakpoints.down('sm')]: {
                fontSize: 10,
            },
        },
    },

    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
                contained: {
                    color: 'white',
                },
            },
        },
    },

    shadows: ['none', ...Array.from(new Array(26)).fill('rgb(0 0 0 / 5%) 0px 1px 3px 0px', 0, 25)],
});

export default theme;
