import React, { useState } from 'react';
// Modules
import { setUser } from 'redux/actions/user';
import ApiResource from 'integrations/api/api_resource';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import validator from 'validator';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Lottie from 'lottie-react';

// Components
import { Container, Typography, TextField, Paper, Box } from '@mui/material';
import { Fade } from 'react-reveal';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';

// Lottie
import SuccessAnimation from 'assets/animations/party-success.json';
// styles
import useStyles from './styles';

export default function ForgotPassword() {
    const classes = useStyles();
    const [state, setState] = useState();
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    async function processSubmit(data) {
        setSubmitting(true);
        try {
            await axios.patch(`${ApiResource.endpoint}/self-employment/reset-password-request`, {
                ...data,
            });

            setSnackbar({
                message: 'Email sent to your inbox',
            }),
                setState(1);
        } catch (error) {
            dispatch(
                setSnackbar({
                    severity: 'error',
                    message: checkError(error),
                }),
            );
        }
        setSubmitting(false);
    }

    if (state === 1) {
        return (
            <Fade up>
                <Container maxWidth="md" className={classes.root}>
                    <Lottie loop={false} animationData={SuccessAnimation} className={classes.animation} />
                    <Typography sx={{ marginTop: 4, fontWeight: '700' }} variant="h3">
                        Reset Password email sent!
                    </Typography>
                    <Typography sx={{ marginBottom: 4 }} variant="h6">
                        An email containing a link was sent to your email
                    </Typography>
                    <LoadingButton loading={submitting} onClick={handleSubmit(processSubmit)}>
                        Didn't receive a link? Click here to resend.
                    </LoadingButton>
                </Container>
            </Fade>
        );
    }

    return (
        <form onSubmit={handleSubmit(processSubmit)}>
            <Fade up>
                <Container maxWidth="sm" className={classes.root}>
                    <Paper sx={{ paddingY: 3, paddingX: 4 }}>
                        <Typography
                            sx={{
                                marginTop: 2,
                                fontWeight: '500',
                            }}
                            variant="h3"
                        >
                            Password Recovery
                        </Typography>
                        <Typography variant="font3" sx={{ fontWeight: '400', marginTop: 2, marginBottom: 2 }}>
                            Input your email and we'll send you a link to reset your password
                        </Typography>
                        <input type="submit" style={{ display: 'none' }} />
                        <TextField
                            error={!!errors.email}
                            label="Email Address"
                            placeholder="e.g. example@example.com"
                            variant="outlined"
                            fullWidth
                            className={classes.field}
                            helperText={errors?.email?.message}
                            sx={{ marginBottom: 2 }}
                            {...register('email', {
                                required: 'Invalid email address',
                                validate: (value) => validator.isEmail(value),
                            })}
                        />
                        <Box sx={{ marginTop: 2 }}>
                            <LoadingButton
                                variant="contained"
                                loading={submitting}
                                className={classes.button}
                                onClick={handleSubmit(processSubmit)}
                            >
                                Submit
                            </LoadingButton>
                        </Box>
                    </Paper>
                </Container>
            </Fade>
        </form>
    );
}
