import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: 40,
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    animation: {
        maxWidth: '40%',
    },
    field: {
        [`& fieldset`]: {
            borderRadius: 2,
        },
    },
    button: {
        borderRadius: 2,
    },
}));

export default useStyles;
