import { makeStyles } from '@mui/styles';

import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    button: {
        paddingTop: 18,
        paddingBottom: 18,
    },
    image: {
        maxWidth: '100%',
    },
}));

export default useStyles;
