import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: theme.spacing(4),
        flexGrow: 1,

        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(2),
        },
    },
}));

export default useStyles;
