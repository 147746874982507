import { makeStyles } from '@mui/styles';

import Background from 'assets/images/backgrounds/pattern.svg';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
}));

export default useStyles;
