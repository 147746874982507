import React, { useEffect, useState } from 'react';
// Modules
import phone from 'phone';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import { setUser } from 'redux/actions/user';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import validator from 'validator';
import { useForm } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';
// Components
import { Container, Typography, TextField, Paper, Box, InputAdornment, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Fade } from 'react-reveal';
import { Link } from 'react-router-dom';
import { Lock } from '@mui/icons-material';
// styles
import useStyles from './styles';
import NumericInput from 'components/inputs/NumericInput';

export default function Signup() {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const [state, setState] = useState(0);
    const [data, setData] = useState({});
    const [taxReturn, setTaxReturn] = useState('2019');
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    async function processSubmit(data) {
        setSubmitting(true);
        try {
            const email = data.email.toLowerCase();
            const response = await axios.post(`${ApiResource.endpoint}/self-employment`, {
                ...data,
                email,
            });

            dispatch(setUser({ authToken: response.data.token, ...response.data.form }));
        } catch (error) {
            dispatch(
                setSnackbar({
                    severity: 'error',
                    message: checkError(error),
                }),
            );
            setSubmitting(false);
        }
    }

    return (
        <form onSubmit={handleSubmit(processSubmit)}>
            <Fade up>
                <Container maxWidth="sm" className={classes.root}>
                    <Box sx={{ paddingY: 3, paddingX: 4 }}>
                        <Typography
                            sx={{
                                marginTop: 2,
                                fontWeight: '500',
                            }}
                            variant="h3"
                        >
                            Welcome to TRC Self Employment Tax Credit!
                        </Typography>
                        <Typography variant="font3" sx={{ fontWeight: '400', marginTop: 2 }}>
                            We help self employed individuals attain their Covid Relief Taxes! Already started an application?{' '}
                            <Typography
                                variant="font3"
                                sx={{ display: 'inline', fontWeight: '400' }}
                                component={Link}
                                color="primary"
                                to="/login"
                            >
                                Log in here
                            </Typography>
                        </Typography>
                        <input type="submit" style={{ display: 'none' }} />
                        <Box sx={{ marginTop: 3 }} className={classes.form}>
                            <TextField
                                error={!!errors.email}
                                label="Email Address"
                                placeholder="e.g. example@example.com"
                                variant="outlined"
                                fullWidth
                                className={classes.field}
                                helperText={errors?.email?.message}
                                {...register('email', {
                                    required: 'Invalid email address',
                                    validate: (value) => validator.isEmail(value),
                                })}
                            />
                            <TextField
                                error={!!errors.name}
                                label="Full name"
                                placeholder="e.g. Don Butterscotch"
                                variant="outlined"
                                fullWidth
                                className={classes.field}
                                helperText={errors?.name?.message}
                                {...register('name', {
                                    required: 'Required Field',
                                })}
                            />
                            <TextField
                                error={!!errors.phone}
                                label="Phone Number"
                                placeholder="e.g. 6025555555"
                                variant="outlined"
                                fullWidth
                                className={classes.field}
                                helperText={errors?.phone?.message}
                                {...register('phone', {
                                    required: 'Invalid phone number',
                                    validate: (value) => phone(value).isValid,
                                })}
                            />
                        </Box>
                        <LoadingButton
                            variant="contained"
                            sx={{ marginTop: 2, paddingX: 4 }}
                            className={classes.button}
                            loading={submitting}
                            onClick={handleSubmit(processSubmit)}
                        >
                            Start
                        </LoadingButton>

                        <Box sx={{ marginTop: 3 }}>
                            <Typography variant="font4">
                                * By providing my email and phone number, I consent to receive email and text messages from The Relief
                                Consultants. Msg & data rates may apply.{' '}
                            </Typography>
                            <Box className={classes.center} sx={{ marginTop: 1 }}>
                                <Lock sx={{ fontSize: 16 }} />
                                <Typography variant="font4">Your data is secure</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Fade>
        </form>
    );
}
