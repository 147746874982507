import React, { useEffect } from 'react';

// Modules
import { useDispatch } from 'react-redux';
// Components
import { Navigate, Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import Navigation from 'components/Navigation/Navigation';
import Footer from 'components/Widgets/Footer/Footer';

import Home from './Home/Home';
import Signup from './Signup/Signup';
import Login from './Login/Login';
import useStyles from './styles';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ResetPassword from './ResetPassword/ResetPassword';
import Authenticate from './Authenticate/Authenticate';

const UnauthStack = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Navigation />
            <Box sx={{ flexGrow: 1 }}>
                <Routes>
                    {/* <Route path="/" component={TypeformCheck} /> */}
                    <Route path="" element={<Home />} />
                    <Route path="start" element={<Signup />} />
                    <Route path="authenticate" element={<Authenticate />} />
                    <Route path="login" element={<Login />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                    <Route path="reset-password" element={<ResetPassword />} />
                    {/* End Routes */}

                    <Route
                        path="*"
                        element={
                            <Navigate
                                to="/login"
                                state={{
                                    redirect: `${window.location.pathname}${window.location.search}`,
                                }}
                                replace
                            />
                        }
                    />
                </Routes>
            </Box>
            <Footer sx={{ marginTop: 6 }} />
        </Box>
    );
};

export default UnauthStack;
