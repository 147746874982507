import React, { useState } from 'react';
import { Box, Button, FormControl, FormControlLabel, InputAdornment, Paper, Radio, RadioGroup, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { ChevronLeft } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import useStyles from './styles';
import NumericInput from 'components/inputs/NumericInput';

export default function TaxesOwed({ form, onBack, onError, onContinue }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch,
    } = useForm({
        defaultValues: {
            amount: form.fields?.taxesOwed?.amount,
            oweTaxesToIrs: form.fields?.taxesOwed?.oweTaxesToIrs,
        },
    });

    const oweTaxes = watch('oweTaxesToIrs', form.fields?.taxesOwed?.oweTaxesToIrs);

    async function handleContinue(data) {
        setSubmitting(true);
        try {
            const response = await axios.patch(
                `${ApiResource.endpoint}/self-employment`,
                {
                    step: 2,
                    taxesOwed: {
                        ...data,
                    },
                },
                {
                    headers: {
                        'x-auth': form.authToken,
                    },
                },
            );

            onContinue(response.data.form);
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    return (
        <form onSubmit={handleSubmit(handleContinue)}>
            <Box className={classes.root}>
                <Typography variant="h5" fontWeight={'400'} sx={{ marginBottom: 1 }}>
                    Taxes Owed to IRS
                </Typography>
                <Typography variant="font2" fontWeight={'400'} sx={{ marginBottom: 4 }}>
                    Add some description text about why we are asking for this information. Add some description text about why we are
                    asking for this information. Add some description text about why we are asking for this information. Add some
                    description text about why we are asking for this information.
                </Typography>
                <input type="submit" style={{ display: 'none' }} />
                <Box sx={{ marginTop: 4 }}>
                    <Typography variant="h4" sx={{ fontWeight: '700', marginBottom: 3 }}>
                        Do you currently owe taxes to the IRS?
                    </Typography>
                    <FormControl>
                        <Controller
                            name="oweTaxesToIrs"
                            control={control}
                            rules={{ required: 'Field Required' }}
                            render={({ field }) => (
                                <RadioGroup {...field} row onChange={(event, value) => field.onChange(value)} value={field.value}>
                                    <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="2" control={<Radio />} label="No" />
                                </RadioGroup>
                            )}
                        />
                    </FormControl>
                    {errors?.oweTaxesToIrs && (
                        <Typography variant="font5" color="error">
                            {errors?.oweTaxesToIrs.message}
                        </Typography>
                    )}
                </Box>

                {oweTaxes === '1' && (
                    <Box className={classes.formField} sx={{ marginTop: 4 }}>
                        <Typography>How much do you currently owe to the IRS?</Typography>
                        <NumericInput
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            placeholder="ex: 50,000"
                            fullWidth
                            precision={0}
                            decimalChar="."
                            thousandChar=","
                            error={!!errors.amount}
                            helperText={errors?.amount?.message}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            {...register('amount', {
                                required: 'Field is required',
                            })}
                        />
                    </Box>
                )}

                <Box className={classes.controls} sx={{ marginTop: 6 }}>
                    <Button onClick={onBack} startIcon={<ChevronLeft />}>
                        Back
                    </Button>
                    <LoadingButton loading={submitting} onClick={handleSubmit(handleContinue)} variant="contained">
                        Continue
                    </LoadingButton>
                </Box>
            </Box>
        </form>
    );
}
