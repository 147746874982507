import { makeStyles } from '@mui/styles';
import theme from 'theme';

const HEADER_HEIGHT = 110;

const useStyles = makeStyles(() => ({
    navContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > div:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
    navContainerBlank: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > div:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
    toolbar: {
        backgroundColor: '#F5F5F7',
        color: 'black',
        minHeight: HEADER_HEIGHT,
    },
    logo: {
        height: 35,
    },
    rightContent: {
        display: 'flex',
        alignItems: 'center',
    },

    link: {
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    activeLink: {
        fontWeight: '600',
    },

    notificationContainer: {
        backgroundColor: '#F4F5F8',
        padding: '9px 12px',
        paddingBottom: 6,
        borderRadius: 6,
        cursor: 'pointer',

        transition: '0.2s all ease-in',
        '&:hover': {
            backgroundColor: '#F0F0F2',
        },
    },

    verticalDivider: {
        backgroundColor: 'rgba(0,0,0,1)',
        width: 1,
        height: 20,
        margin: '0px 16px 0px 0px',
    },
}));

export default useStyles;
