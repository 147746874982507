import { makeStyles } from '@mui/styles';

import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: 40,
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: '50vh',
    },
    form: {
        width: '100%',

        '& > div:not(:last-child)': {
            marginBottom: theme.spacing(2),
        },
    },
    field: {
        [`& fieldset`]: {
            borderRadius: 2,
        },
    },
    button: {
        borderRadius: 2,
    },
    center: {
        display: 'flex',
        alignItems: 'center',
    },

    /** State 0 */
    selection: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: theme.spacing(2),
    },
    select: {
        padding: '32px',
        textAlign: 'center',

        cursor: 'pointer',
        transition: '0.2s all ease-in',
        '&:hover': {
            transform: 'translateY(-6px)',
        },
    },
}));

export default useStyles;
