import { makeStyles } from '@mui/styles';
import theme, { AppColors } from 'theme';

const HEADER_HEIGHT = 80;

const useStyles = makeStyles(() => ({
    /** Drawer */
    logo: {
        maxWidth: '70%',
    },

    drawer: {
        width: '100%',
        backgroundImage: 'none',
    },
    drawerBox: {
        padding: 20,
    },

    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginBottom: theme.spacing(4),
    },

    drawerItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        margin: '8px 0px',
        textDecoration: 'none',
        lineHeight: '20px',
        fontSize: 18,
        fontWeight: '500',
        color: AppColors.Gray1,
        cursor: 'pointer',
        '-webkit-transition': 'all .4s ease-in-out',
        '-o-transition': 'all .4s ease-in-out',
        transition: 'all .4s ease-in-out',
        position: 'relative',
    },

    itemActive: {
        fontWeight: 800,
        borderRadius: 5,
        color: AppColors.Primary,
    },

    link: {
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    activeLink: {
        fontWeight: '600',
    },

    divider: {
        margin: '12px 0px',
        width: '100%',
        height: 1,
        backgroundColor: 'rgba(0,0,0,1)',
    },
}));

export default useStyles;
