import React, { useState } from 'react';
import { Box, Button, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import useStyles from './styles';
import { useForm } from 'react-hook-form';
import NumericInput from 'components/inputs/NumericInput';
import { LoadingButton } from '@mui/lab';
import { ChevronLeft } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';

export default function Form7202_2021({ form, onBack, onError, onContinue }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            sickDays: form.fields?.form7202_2021?.sickDays,
            netIncome: form.fields?.form7202_2021?.netIncome,
            sickWages: form.fields?.form7202_2021?.sickWages,
            fiscalOr10: form.fields?.form7202_2021?.fiscalOr10,
            sickDaysCaredFor: form.fields?.form7202_2021?.sickDaysCaredFor,
            sickCaredForChild: form.fields?.form7202_2021?.sickCaredForChild,
            sickWagesCaredFor: form.fields?.form7202_2021?.sickWagesCaredFor,
            sickWagesCaredForChild: form.fields?.form7202_2021?.sickWagesCaredForChild,
        },
    });

    console.log(form.fields?.form7202_2021?.sickWagesCaredForChild);

    async function handleContinue(data) {
        setSubmitting(true);
        try {
            const response = await axios.patch(
                `${ApiResource.endpoint}/self-employment`,
                {
                    step: 4,
                    form7202_2021: {
                        ...data,
                    },
                },
                {
                    headers: {
                        'x-auth': form.authToken,
                    },
                },
            );

            onContinue(response.data.form);
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    return (
        <form onSubmit={handleSubmit(handleContinue)}>
            <Box className={classes.root}>
                <Typography variant="h5" fontWeight={'400'} sx={{ marginBottom: 1 }}>
                    2021 Information
                </Typography>
                <Typography variant="font2" fontWeight={'400'} sx={{ marginBottom: 4 }}>
                    Add some description text about why we are asking for this information. Add some description text about why we are
                    asking for this information. Add some description text about why we are asking for this information. Add some
                    description text about why we are asking for this information.
                </Typography>
                <input type="submit" style={{ display: 'none' }} />
                <Box className={classes.formField}>
                    <Typography>What was your net income in 2020?</Typography>
                    <NumericInput
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        placeholder="ex: 5"
                        fullWidth
                        precision={0}
                        decimalChar="."
                        thousandChar=","
                        error={!!errors.netIncome}
                        helperText={errors?.netIncome?.message}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        {...register('netIncome', {
                            required: 'Field is required',
                        })}
                    />
                </Box>
                <Box className={classes.formField}>
                    <Typography>If you filed after march 10th how many days? Or enter 10.</Typography>
                    <NumericInput
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        placeholder="ex: 5"
                        fullWidth
                        precision={0}
                        decimalChar="."
                        thousandChar=","
                        error={!!errors.fiscalOr10}
                        helperText={errors?.fiscalOr10?.message}
                        {...register('fiscalOr10', {
                            required: 'Field is required',
                        })}
                    />
                </Box>
                <Box className={classes.formField}>
                    <Typography>How many days were you sick during 2020?</Typography>
                    <NumericInput
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        placeholder="ex: 5"
                        fullWidth
                        precision={0}
                        decimalChar="."
                        thousandChar=","
                        error={!!errors.sickDays}
                        helperText={errors?.sickDays?.message}
                        {...register('sickDays', {
                            required: 'Field is required',
                        })}
                    />
                </Box>
                <Box className={classes.formField}>
                    <Typography>How much were you paid while sick 2020?</Typography>
                    <NumericInput
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        placeholder="ex: 5"
                        fullWidth
                        precision={0}
                        decimalChar="."
                        thousandChar=","
                        error={!!errors.sickWages}
                        helperText={errors?.sickWages?.message}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        {...register('sickWages', {
                            required: 'Field is required',
                        })}
                    />
                </Box>
                <Box className={classes.formField}>
                    <Typography>How many days were you caring for a sick individual during 2020?</Typography>
                    <NumericInput
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        placeholder="ex: 5"
                        fullWidth
                        precision={0}
                        decimalChar="."
                        thousandChar=","
                        error={!!errors.sickDaysCaredFor}
                        helperText={errors?.sickDaysCaredFor?.message}
                        {...register('sickDaysCaredFor', {
                            required: 'Field is required',
                        })}
                    />
                </Box>
                <Box className={classes.formField}>
                    <Typography>How how much were you paid while caring for a sick individual during 2020?</Typography>
                    <NumericInput
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        placeholder="ex: 5"
                        fullWidth
                        precision={0}
                        decimalChar="."
                        thousandChar=","
                        error={!!errors.sickWagesCaredFor}
                        helperText={errors?.sickWagesCaredFor?.message}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        {...register('sickWagesCaredFor', {
                            required: 'Field is required',
                        })}
                    />
                </Box>
                <Box className={classes.formField}>
                    <Typography>How many days were you caring for a sick child during 2020?</Typography>
                    <NumericInput
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        placeholder="ex: 5"
                        fullWidth
                        precision={0}
                        decimalChar="."
                        thousandChar=","
                        error={!!errors.sickWagesCaredFor}
                        helperText={errors?.sickWagesCaredFor?.message}
                        {...register('sickCaredForChild', {
                            required: 'Field is required',
                        })}
                    />
                </Box>
                <Box className={classes.formField}>
                    <Typography>How much were you paid while caring for a sick child during 2020?</Typography>
                    <NumericInput
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        placeholder="ex: 5"
                        fullWidth
                        precision={0}
                        decimalChar="."
                        thousandChar=","
                        error={!!errors.sickWagesCaredFor}
                        helperText={errors?.sickWagesCaredFor?.message}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        {...register('sickWagesCaredForChild', {
                            required: 'Field is required',
                        })}
                    />
                </Box>

                <Box className={classes.controls} sx={{ marginTop: 6 }}>
                    <Button onClick={onBack} startIcon={<ChevronLeft />}>
                        Back
                    </Button>
                    <LoadingButton loading={submitting} onClick={handleSubmit(handleContinue)} variant="contained">
                        Continue
                    </LoadingButton>
                </Box>
            </Box>
        </form>
    );
}
