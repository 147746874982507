import React from 'react';
import { Box, Paper, Button, Typography } from '@mui/material';
import SuccessImage from 'assets/images/stock/success.png';
import useStyles from './styles';
import { ChevronLeft } from '@mui/icons-material';

export default function Finished({ onBack }) {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Typography variant="h5" fontWeight={'400'} sx={{ marginBottom: 1 }}>
                Application Submitted!
            </Typography>
            <Typography variant="font2" fontWeight={'400'} sx={{ marginBottom: 4 }}>
                Add some description text about why we are asking for this information. Add some description text about why we are asking
                for this information. Add some description text about why we are asking for this information. Add some description text
                about why we are asking for this information.
            </Typography>
            <input type="submit" style={{ display: 'none' }} />
            <Box sx={{ marginTop: 3 }} className={classes.form}>
                <img src={SuccessImage} className={classes.image} />
            </Box>

            <Box className={classes.controls} sx={{ marginTop: 6 }}>
                <Button onClick={onBack} startIcon={<ChevronLeft />}>
                    Back
                </Button>
                <Button variant="contained">View Status</Button>
            </Box>
        </Box>
    );
}
