import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Hidden, LinearProgress, Step, StepButton, Stepper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import BasicInformation from './Steps/BasicInformation';
import Form7202_2020 from './Steps/Form7202_2020';
import Form7202_2021 from './Steps/Form7202_2021';

import useStyles from './styles';
import Income from './Steps/Income';
import TaxesOwed from './Steps/TaxesOwed';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import { setUser } from 'redux/actions/user';
import Disqualified from './Steps/Disqualified';
import Documents from './Steps/Documents';
import Finished from './Steps/Finished';

const StepLabels = [
    { step: 0, label: 'Basic Information' },
    { step: 1, label: 'Net Income' },
    { step: 2, label: 'Tax Liability' },
    { step: 3, label: '2020 Information' },
    { step: 4, label: '2021 Information' },
    { step: 5, label: 'Documents' },
    { step: 6, label: 'Finished!' },
];

const StepComponents = [BasicInformation, Income, TaxesOwed, Form7202_2020, Form7202_2021, Documents, Finished];

export default function Form() {
    const classes = useStyles();
    const { user } = useSelector(({ user }) => ({ user }));
    const [currentStep, setCurrentStep] = useState(user.step);
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentStep !== user.step) {
            setCurrentStep(user.step);
        }
    }, [user]);

    function handleSetStep(step) {
        if (!StepComponents[step]) return;
        setCurrentStep(step);
    }

    function handleBack() {
        setCurrentStep(currentStep - 1);
    }

    function handleError(error) {
        dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
    }

    function handleContinue(form) {
        dispatch(setUser(form));
    }

    let Component = user.fields.disqualified ? Disqualified : StepComponents[currentStep];

    let percentage = ((currentStep + 1) / StepLabels.length) * 100;

    return (
        <Box className={classes.root}>
            <Container maxWidth={'lg'}>
                <Grid container spacing={3}>
                    <Hidden mdDown>
                        <Grid item xs={12} md={3}>
                            <Stepper activeStep={currentStep} orientation={'vertical'} sx={{ margin: 'auto' }}>
                                {StepLabels.map(({ step, label }) => (
                                    <Step key={label}>
                                        <StepButton sx={{ cursor: 'pointer' }} disableRipple>
                                            {label}
                                        </StepButton>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={9}>
                        <Hidden mdUp>
                            <LinearProgress
                                sx={{
                                    height: 10,
                                    borderRadius: 5,
                                    marginBottom: 2,
                                }}
                                variant="determinate"
                                value={percentage}
                            />
                        </Hidden>
                        <Component form={user} onBack={handleBack} onContinue={handleContinue} onError={handleError} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
