import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(3),
        paddingLeft: 12,
        paddingRight: 12,
        margin: 'auto',
    },
    logo: {
        width: '200px',
    },
}));

export default useStyles;
