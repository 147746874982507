import React, { useState } from 'react';
// Modules
import { setUser } from 'redux/actions/user';
import ApiResource from 'integrations/api/api_resource';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import validator from 'validator';
import { useForm } from 'react-hook-form';
import axios from 'axios';

// Components
import { Container, Typography, TextField, Paper, Box } from '@mui/material';
import { Fade } from 'react-reveal';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';

import Lottie from 'lottie-react';
// Lottie
import SuccessAnimation from 'assets/animations/party-success.json';
// styles
import useStyles from './styles';

export default function Login() {
    const classes = useStyles();
    const [success, setSuccess] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    async function processSubmit(data) {
        setSubmitting(true);
        try {
            await axios.post(`${ApiResource.endpoint}/self-employment/send-login-link`, {
                ...data,
            });
            setSuccess(true);
        } catch (error) {
            dispatch(
                setSnackbar({
                    severity: 'error',
                    message: checkError(error),
                }),
            );
        }
        setSubmitting(false);
    }

    if (success) {
        return (
            <Fade up>
                <Container maxWidth="md" className={classes.root}>
                    <Lottie loop={false} animationData={SuccessAnimation} className={classes.animation} />
                    <Typography sx={{ marginTop: 4, fontWeight: '700' }} variant="h3">
                        Log in link sent!
                    </Typography>
                    <Typography sx={{ marginBottom: 4, marginTop: 1, fontWeight: '400' }} variant="h6">
                        An email containing a link was sent to your email
                    </Typography>
                    <LoadingButton loading={submitting} onClick={handleSubmit(processSubmit)}>
                        Didn't receive a link? Click here to resend.
                    </LoadingButton>
                </Container>
            </Fade>
        );
    }

    return (
        <form onSubmit={handleSubmit(processSubmit)}>
            <Fade up>
                <Container maxWidth="sm" className={classes.root}>
                    <Box sx={{ paddingY: 3, paddingX: 4 }}>
                        <Typography
                            sx={{
                                marginTop: 2,
                                fontWeight: '500',
                            }}
                            variant="h3"
                        >
                            Welcome back to TRC Self Employment Tax Credit!
                        </Typography>
                        <Typography variant="font3" sx={{ fontWeight: '400', marginTop: 2, marginBottom: 2 }}>
                            Input your email to receive a login link. Don't have an account?{' '}
                            <Typography
                                variant="font3"
                                sx={{ display: 'inline', fontWeight: '400' }}
                                component={Link}
                                color="primary"
                                to="/start"
                            >
                                Start here!
                            </Typography>
                        </Typography>
                        <input type="submit" style={{ display: 'none' }} />
                        <TextField
                            error={!!errors.email}
                            label="Email Address"
                            placeholder="e.g. example@example.com"
                            variant="outlined"
                            fullWidth
                            className={classes.field}
                            helperText={errors?.email?.message}
                            {...register('email', {
                                required: 'Invalid email address',
                                validate: (value) => validator.isEmail(value),
                            })}
                        />
                        <Box sx={{ marginTop: 2 }}>
                            <LoadingButton
                                variant="contained"
                                loading={submitting}
                                className={classes.button}
                                onClick={handleSubmit(processSubmit)}
                            >
                                Send Login Link
                            </LoadingButton>
                        </Box>
                    </Box>
                </Container>
            </Fade>
        </form>
    );
}
