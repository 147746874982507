import { combineReducers } from 'redux';

// Import reducers here
import user from './user';
import snackbar from './snackbar';

const rootReducer = combineReducers({
    user,
    snackbar,
});

export default rootReducer;
