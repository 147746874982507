import React from 'react';
import { useSelector } from 'react-redux';
import UnauthStack from 'containers/UnauthStack/UnauthStack';
import AuthStack from 'containers/AuthStack/AuthStack';

const Authorizer = () => {
    const { user } = useSelector(({ user }) => ({ user }));

    if (user) {
        return <AuthStack />;
    }

    return <UnauthStack />;
};

export default Authorizer;
